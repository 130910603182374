import { combineReducers } from '@reduxjs/toolkit';

import entrepreneursSlice from './slices/entrepreneursSlice';
import chantiersSlice from './slices/chantiersSlice';
import reportageSlice from './slices/reportagesSlice';
import authSlice from './slices/authSlice';

const rootReducer = combineReducers({ entrepreneursSlice, chantiersSlice, reportageSlice, authSlice });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
