import { API } from './api';

import { Reportage } from '../helpers/types';
import { uploadFile } from './s3files';
import mapping from '../mappings/reportage';

export async function postReportage(
    reportage: Reportage,
    chantierId: string,
    powalcoId: string,
    file: File,
): Promise<Reportage> {
    try {
        const postData = {
            FileName: reportage.fileName,
            Chantier: chantierId,
            Type: reportage.type,
            PowalcoId: powalcoId,
        };
        const response = await API().post(`/powalco/entrepreneurs/chantiers/upload`, postData);
        const { data } = response;
        const { Upload } = data;
        await uploadFile(Upload, file);
        return reportage;
    } finally {
    }
}

export async function getReportages(): Promise<Reportage[]> {
    const response = await API().get(`/powalco/entrepreneurs/chantiers/upload`);
    const { data } = response;
    return data.Files.map(mapping);
}
