import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Card, Col, Row, Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { Chantier, Reportage, Searched } from '../../helpers/types';
import RenderHighlighted from '../RenderHighlighted/RenderHighLighted';
import { RootState } from '../../rootReducer';
import Error from '../Errors/Error';
import ReportageDetails from './ReportageDetails';
import ReportageTypeTag from './ReportageTypeTag';

interface IChantierInfo {
    chantierId: string | null;
    powalcoId: string | null;
}

interface ReportageListProps {
    chantier: Chantier;
    searched: Searched;
    setPopupChantierInfo: (chantierInfo: IChantierInfo) => void;
    reportages: Reportage[];
}

const ReportageList: React.FC<ReportageListProps> = ({
    chantier,
    searched,
    setPopupChantierInfo,
    reportages,
}: ReportageListProps) => {
    const { request: reportagesRequest } = useSelector((state: RootState) => state.reportageSlice);
    const [selectedReportage, setSelectedReportage] = useState<Reportage>();
    return (
        <Error request={reportagesRequest}>
            {selectedReportage && (
                <Modal
                    width={'85vw'}
                    visible
                    closable
                    onCancel={() => setSelectedReportage(undefined)}
                    title={<Space wrap>{selectedReportage.fileName}</Space>}
                    footer={
                        <Button href={selectedReportage.url} type="primary">
                            Télécharger
                        </Button>
                    }
                >
                    <ReportageDetails reportage={selectedReportage} />
                </Modal>
            )}
            <Row gutter={[16, 16]} style={{ padding: 16, maxWidth: '100%' }}>
                <Col span={24} md={12} lg={8} xl={6}>
                    <Button
                        size="small"
                        //shape="round"
                        type="ghost"
                        style={{
                            width: '100%',
                            height: '100%',
                            minHeight: 115,
                            borderColor: '#F0F0F0',
                            borderStyle: 'dashed',
                            backgroundColor: '#F5F5F5',
                            boxShadow: 'none',
                            fontSize: 18,
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setPopupChantierInfo({
                                powalcoId: chantier.powalcoId,
                                chantierId: chantier.id,
                            });
                        }}
                        icon={<PlusOutlined />}
                    >
                        Ajouter
                    </Button>
                </Col>
                {reportages
                    .sort((a, b) => (b.date || 0) - (a.date || 0))
                    .map((reportage) => (
                        <Col key={reportage.id} span={24} md={12} lg={8} xl={6}>
                            <Card
                                style={{ width: '100%', cursor: 'pointer' }}
                                onClick={() => setSelectedReportage(reportage)}
                            >
                                <ReportageTypeTag type={reportage.type} />
                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    <RenderHighlighted str={reportage.fileName} searched={searched} />
                                </div>
                                {reportage.date && (
                                    <span style={{ color: 'silver' }}>{moment(reportage.date).calendar()}</span>
                                )}
                            </Card>
                        </Col>
                    ))}
            </Row>
        </Error>
    );
};

export default ReportageList;
