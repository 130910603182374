import React from 'react';
import { Tag } from 'antd';
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';

import { ReportageType } from '../../helpers/types';

interface ReportageTypeTagProps {
    type: ReportageType;
}

const ReportageTypeTag = ({ type }: ReportageTypeTagProps) => {
    return (
        <Tag color={type == 'EDLS' ? 'magenta' : 'gold'}>
            {type == 'EDLS' ? <LoginOutlined /> : <LogoutOutlined />} {type}
        </Tag>
    );
};

export default ReportageTypeTag;
