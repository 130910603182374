import { Divider, Tag } from 'antd';
import React, { FC } from 'react';
import moment from 'moment';

import { Reportage } from '../../helpers/types';
import PDFViewer from '../PDFViewer/PDFViewer';
import ReportageTypeTag from './ReportageTypeTag';
import UploadStatus from './UploadStatus';

interface ReportageDetailsProps {
    reportage: Reportage;
    confirmation?: boolean;
}

const ReportageDetails: FC<ReportageDetailsProps> = ({ reportage, confirmation }: ReportageDetailsProps) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'stretch', width: '100%' }}>
            <div style={{ flexGrow: 3 }}>
                <section>
                    <h3>Type</h3>
                    <ReportageTypeTag type={reportage.type} />
                </section>
                {!confirmation && (
                    <section>
                        <h3>Nom de fichier Powalco</h3>
                        {reportage.powalco_filename}
                    </section>
                )}
                <section>
                    <h3>Date</h3>
                    {moment(reportage.date).calendar()}
                </section>
                {!confirmation && (
                    <section>
                        <h3>Upload Status</h3>
                        <UploadStatus title="Sharepoint" status={reportage.sharepointUpload} />
                        <UploadStatus title="Powalco" status={reportage.powalcoUpload} />
                    </section>
                )}
            </div>
            <Divider type="vertical" />
            <div style={{ flexGrow: 5 }}>
                <PDFViewer url={reportage.url || ''} />
            </div>
        </div>
    );
};

export default ReportageDetails;
