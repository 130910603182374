import { Chantier } from '../helpers/types';

interface IServerChantier {
    Id: string;
    Street: string;
    Numero: string;
    PostCode: string;
    Localite: string;
    PowalcoId: string;
}

function mapServerChantierToChantier(serverChantier: IServerChantier): Chantier {
    return {
        id: serverChantier.Id,
        street: serverChantier.Street,
        numero: serverChantier.Numero,
        postCode: serverChantier.PostCode,
        localite: serverChantier.Localite,
        powalcoId: serverChantier.PowalcoId,
    };
}

function mapServerChantiersToChantiers(serversChantiers: IServerChantier[]): Chantier[] {
    return serversChantiers.map(mapServerChantierToChantier);
}

export default mapServerChantiersToChantiers;
