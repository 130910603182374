import { Entrepreneur } from '../helpers/types';
import { API } from './api';

import mapping from '../mappings/entrepreneurs';

async function fetchEntrepreneurs(): Promise<Entrepreneur[]> {
    const test = await API().get('/powalco/entrepreneurs', { params: { PageSize: 10000 } });
    const {
        data: { Data: serverEntrepreneurs },
    } = test;
    const entrepreneurs = mapping(serverEntrepreneurs);
    return entrepreneurs;
}

export default fetchEntrepreneurs;
