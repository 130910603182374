import React from 'react';

import Header from './components/Header/Header';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import routes, { RouteType } from './routes';

import './custom-antd.css';
import Authenticator from './components/Authenticator/Authenticator';
import UnauthorizedError from './components/Errors/UnauthorizedError';
import ConditionsGenerales from './components/ConditionsGenerales/ConditionsGenerales';
import useDashboard from './hooks/useDashboard';
import { Spin } from 'antd';

const Default = () => {
    const dashboard = useDashboard();
    const { Permissions: permissions = [] } = dashboard.data || {};
    if (dashboard.loading) return <Spin />;
    if (permissions.includes('admin')) {
        return <Redirect to="/admin" />;
    }
    if (permissions.includes('entrepreneur')) {
        return <Redirect to="/entrepreneur" />;
    }
    return <UnauthorizedError error={dashboard.error} />;
};

const App: React.FC = () => {
    const dashboard = useDashboard();
    const { Permissions: permissions = [] } = dashboard.data || {};
    return (
        <Router>
            <Header />
            <ConditionsGenerales />
            <Authenticator>
                <Switch>
                    {routes.map(({ path, component, permission }: RouteType) => (
                        <Route
                            key={path}
                            path={path}
                            component={
                                permission && permissions.includes(permission)
                                    ? component //
                                    : () => <Redirect to="/" />
                            }
                        />
                    ))}
                    <Route path="/" component={Default} />
                </Switch>
            </Authenticator>
        </Router>
    );
};

export default App;
