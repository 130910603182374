import React from 'react';
import useLogin from '../../hooks/useLogin';

interface AuthenticatorProps {
    children: any;
}

const Authenticator: React.FC<AuthenticatorProps> = ({ children }: AuthenticatorProps) => {
    const loggedIn = !!useLogin();
    return loggedIn ? children : null;
};

export default Authenticator;
