import React, { useEffect, useState, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Space, Input } from 'antd';
import * as _ from 'lodash';

import { getEntrepreneurs } from '../../slices/entrepreneursSlice';

import { RootState } from '../../rootReducer';
import EntrepreneurList from './EntrepreneurList';
import { Entrepreneur, Searched } from '../../helpers/types';
import isSearched from '../../helpers/isSearched';

const { Search } = Input;

function isActif(e: Entrepreneur) {
    return e.status === 'actif' || e.status === 'en attente';
}

type EntrepreneurKeys = keyof Entrepreneur;
const SEARCH_KEYS: EntrepreneurKeys[] = ['name', 'mail', 'fournisseurId'];

function isEntrepreneurSearched(e: Entrepreneur, searched: Searched) {
    const fullSearchString: string = SEARCH_KEYS.map((k) => e[k] || '').join('');
    return isSearched(fullSearchString, searched);
}

function AdminPage(): ReactElement {
    const dispatch = useDispatch();

    const { data, loading, editing, resettingPwd } = useSelector((state: RootState) => state.entrepreneursSlice);
    useEffect(() => {
        dispatch(getEntrepreneurs());
    }, [dispatch]);

    const [searched, setSearched] = useState<Searched>(null);
    const filteredData = data && data.filter((e) => isEntrepreneurSearched(e, searched));
    const [actifs, inactifs] = filteredData ? _.partition(filteredData, isActif) : [null, null];

    return (
        <div style={{ padding: 30, width: '100%' }}>
            <Space wrap size={48} align="center">
                <h2 style={{ fontSize: 48 }}>Gérer les Entrepreneurs</h2>
                <div>
                    <Search
                        placeholder="Rechercher par nom, id ou mail"
                        value={searched || ''}
                        onChange={(e) => {
                            setSearched(e.target.value);
                        }}
                        allowClear
                        size="large"
                        style={{ marginBottom: 24, width: 400 }}
                    />
                </div>
            </Space>
            <br />
            <h3 style={{ fontSize: 24 }}>Actifs</h3>
            <EntrepreneurList searched={searched} loading={loading || !!editing || !!resettingPwd} data={actifs} />
            <br />
            <h3 style={{ fontSize: 24 }}>Inactifs</h3>
            <EntrepreneurList searched={searched} loading={loading || !!editing || !!resettingPwd} data={inactifs} />
        </div>
    );
}

export default AdminPage;
