import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../rootReducer';
import { redirectToLoginPage } from '../services/login';
import { retrieveToken } from '../slices/authSlice';

export default () => {
    const dispatch = useDispatch();
    const {
        auth: { token },
    } = useSelector((state: RootState) => state.authSlice);
    useEffect(() => {
        if (!token && !window.location.hash) {
            redirectToLoginPage();
        } else {
            dispatch(retrieveToken());
        }
    }, [token]);
    return token;
};
