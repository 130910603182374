import { Tag, Tooltip } from 'antd';
import React, { FC } from 'react';
import { UploadStatus as UploadStatusType } from '../../helpers/types';
import moment from 'moment';

interface UploadStatusProps {
    title: string;
    status?: UploadStatusType;
}

const UploadStatus: FC<UploadStatusProps> = ({ title, status }: UploadStatusProps) => {
    const color = status?.success ? 'green' : 'red';
    const text = status?.success
        ? `Ce fichier à été uploadé avec succes ${moment(status.timestamp).calendar()}`
        : `L'upload de ce fichier à échoué. Dernière tentative ${moment(
              status?.timestamp,
          ).calendar()}. Nous prenons en charge ce problème`;
    return (
        <Tooltip title={status ? text : 'Upload en cours...'}>
            <Tag color={status && color} onClick={() => console.log(status?.error)}>
                {title}
            </Tag>
        </Tooltip>
    );
};

export default UploadStatus;
