import React from 'react';
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { method } from 'lodash';
import { notification } from 'antd';
import store from '../store';
import errorMapping from '../mappings/errors';
import { FrownOutlined } from '@ant-design/icons';

export const API = () => {
    const { token } = store.getState().authSlice.auth;
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API,
        headers: token && {
            Authorization: 'Bearer ' + token,
            'X-Api-Key': process.env.REACT_APP_API_KEY,
        },
    });
    const ret: any = {};
    Object.keys(instance).forEach((key: any) => {
        const value = instance[key as keyof AxiosInstance];
        if (typeof value == 'function') {
            const value_: any = (...args: any) => {
                return new Promise((resolve) => resolve((value as CallableFunction)(...args)))
                    .catch((resp: AxiosError) => {
                        throw errorMapping(resp);
                    })
                    .catch((err: string) => {
                        console.error(err);
                        notification.error({
                            message: 'Error',
                            duration: 4,
                            style: { backgroundColor: '#fff2f0', border: '1px solid #ffccc7' },
                            description: err,
                        });
                        throw err;
                    });
            };
            ret[key] = value_;
        } else {
            ret[key] = value;
        }
    });
    return ret as AxiosInstance;
};
