import React from 'react';
import { Result, Button, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const UnauthorizedError: React.FC<{ error?: string | null }> = ({ error }: { error?: string | null }) => {
    return (
        <Result
            status="error"
            title="Oops"
            subTitle={error || "Vous n'êtes pas authorizé à accéder à la Plateforme Resa Entrepreneur"}
            extra={
                <a href="https://my.resa.be">
                    <Button type="primary">Retour vers MyResa</Button>
                </a>
            }
        ></Result>
    );
};

export default UnauthorizedError;
