import { FC } from 'react';
import AdminPage from './components/AdminPage/AdminPage';
import ChantiersPage from './components/ChantiersPage/ChantiersPage';

export type RouteType = {
    title: string;
    path: string;
    component: FC;
    permission: string;
};

const routes: RouteType[] = [
    {
        title: 'Administration panel',
        path: '/admin',
        component: AdminPage,
        permission: 'admin',
    },
    {
        title: 'Mes Chantiers',
        path: '/entrepreneur',
        component: ChantiersPage,
        permission: 'entrepreneur',
    },
];

export default routes;
