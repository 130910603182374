import { AxiosResponse, AxiosError } from 'axios';
export default (error: AxiosError): string => {
    const { status, data } = (error?.response || {}) as AxiosResponse;
    if (status === 404) {
        return 'Aucune données trouvées';
    }
    console.error(error, status, data);
    if (data?.Message) {
        return data.Message;
    }
    return 'Une erreur est survenue réessayez plus tard';
};
