import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../rootReducer';
import { getDashboard } from '../slices/authSlice';
import useLogin from './useLogin';

export default () => {
    const isLoggedIn = !!useLogin();
    const dispatch = useDispatch();
    const request = useSelector((state: RootState) => state.authSlice.dashboard);
    const [shouldDispatch, setShouldDispatch] = useState(
        isLoggedIn && !request.data && !request.loading && !request.error,
    );
    useEffect(() => {
        if (shouldDispatch) {
            setShouldDispatch(false);
            dispatch(getDashboard());
        }
    }, [shouldDispatch, dispatch]);

    return request;
};
