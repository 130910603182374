import { Result, Spin } from 'antd';
import React, { FC } from 'react';
import { Request } from '../../helpers/types';

interface ErrorProps {
    request: Request<unknown>;
    children?: any;
}

const Error: FC<ErrorProps> = ({ request, children }: ErrorProps) => {
    return (
        <Spin spinning={request.loading && !request.data}>
            {request.error ? <Result status="error" subTitle={request.error} /> : children}
        </Spin>
    );
};

export default Error;
