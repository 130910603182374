import axios from 'axios';
import mockApiCall from '../helpers/mockApiCall';
import {} from '../helpers/types';
import { API } from './api';

const WEB_URL = window.location.origin;
const ADFS_URL = 'https://login.resa.be/adfs/oauth2';
const client_id = process.env.REACT_APP_ADFS_CLIENT_ID;

const ADFS = axios.create({
    baseURL: ADFS_URL,
    params: {
        client_id,
    },
});

export async function getLoginToken(login: string, password: string): Promise<string> {
    const req = await API()
        .post('/utilisateurs/authenticate', {
            Username: login,
            Password: password,
        })
        .catch((e) => {
            console.error(e);
            throw 'Login ou Mot de passe invalide';
        });
    return req.data.IdToken;
}

export function redirectToLoginPage(): void {
    window.location.href = `${ADFS_URL}/authorize?client_id=${client_id}&response_type=id_token&redirect_uri=${WEB_URL}`;
}

export async function getDashboard(): Promise<any> {
    const req = await API().get('/me/dashboard', { params: { Quick: true } });
    return req.data;
}

export async function logout(): Promise<void> {
    API().post('/utilisateurs/authenticate/logout');
    window.location.href = `${ADFS_URL}/logout?wa=wsignout1.0&client_id=${client_id}&wreply=${WEB_URL}`; //TODO: Redirect is not working
}
