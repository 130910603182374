import React, { ReactElement } from 'react';
import { Searched } from '../../helpers/types';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';
import colors from '../../helpers/colors';

type CustomRendererType = (str: string) => string;

interface IRenderHighlighted {
    str: string;
    searched: Searched;
    customRenderer?: CustomRendererType;
}

const defaultRenderer: CustomRendererType = (str) => str;

function RenderHighlighted({ str, searched, customRenderer = defaultRenderer }: IRenderHighlighted): ReactElement {
    return searched ? (
        <Highlighter
            highlightStyle={{ backgroundColor: 'transparent', color: colors.lightorange, padding: 0 }}
            sanitize={_.deburr}
            searchWords={[searched]}
            autoEscape={true}
            textToHighlight={customRenderer(str)}
        />
    ) : (
        <>{customRenderer(str)}</>
    );
}

export default RenderHighlighted;
