import React from 'react';
import { Row, Col, Button, Popover } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import logo from '../../assets/logo.png';
import { logout } from '../../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

import colors from '../../helpers/colors';
import useDashboard from '../../hooks/useDashboard';

function Header() {
    const dispatch = useDispatch();
    const { token } = useSelector((state: RootState) => state.authSlice.auth);
    const { Email, Permissions = [] } = useDashboard().data || {};
    const isAdmin = Permissions.includes('admin');
    return (
        <Row justify="space-between" align="middle" style={{ border: '1px solid rgb(235, 237, 240)', padding: 12 }}>
            <Col>
                <a href="https://powalco.resa.be" target="_blank" rel="noreferrer">
                    <img alt="resa_logo" src={logo} style={{ height: 60 - 24 }} />
                </a>
            </Col>
            {token && (
                <Col>
                    <Popover
                        content={
                            <span>
                                <p style={{ color: colors.orange, paddingBottom: 16, borderBottom: '1px solid grey' }}>
                                    {isAdmin ? 'Admin' : 'Entrepreneur'}
                                </p>
                                <a
                                    style={{ color: 'black', textDecorationLine: 'underline' }}
                                    onClick={() => {
                                        dispatch(logout());
                                    }}
                                >
                                    Se déconnecter
                                </a>
                            </span>
                        }
                        trigger="click"
                        placement="bottomRight"
                    >
                        <Button shape="round" style={{ textTransform: 'uppercase' }}>
                            <UserOutlined style={{ color: colors.orange }} />
                            {Email}
                            <DownOutlined style={{ color: colors.orange }} />
                        </Button>
                    </Popover>
                </Col>
            )}
        </Row>
    );
}

export default Header;
