import React, { ReactElement, useState, useEffect } from 'react';
import { Modal } from 'antd';

import { logout } from '../../slices/authSlice';
import { useDispatch } from 'react-redux';

type yesNo = 'yes' | 'no';
const acceptedLocalStorage: yesNo = localStorage.getItem('acceptedConditionsGenerales') as yesNo | 'no';

function ConditionsGenerales(): ReactElement | null {
    const dispatch = useDispatch();
    const [accepted, setAccepted] = useState<yesNo>(acceptedLocalStorage);
    useEffect(() => {
        // save to localStorage on change
        localStorage.setItem('acceptedConditionsGenerales', accepted);
    }, [accepted]);

    function refuse() {
        setAccepted('no');
        dispatch(logout());
    }
    function accept() {
        setAccepted('yes');
    }
    return (
        <Modal
            visible={accepted !== 'yes'}
            title="Conditions Générales"
            okText="J'accepte"
            cancelText="Je refuse et je suis déconnecté du portail"
            closable={false}
            maskClosable={false}
            onCancel={refuse}
            onOk={accept}
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
        >
            <p>
                {`En consultant et ou en utilisant le portail Internet powalco.resa.be et les informations qui
                y figurent, vous acceptez les conditions ci-dessous. RESA est susceptible de les modifier à tout moment.`}
            </p>
            <p>
                {`L’entrepreneur (= l’utilisateur du portail) qui utilise ce portail est seul responsable des
                informations/photographies qu’il y dépose. L'utilisateur s'engage à utiliser ce portail Internet dans le
                respect des lois et des normes en vigueur. L'utilisateur ne peut l'utiliser d'aucune manière qui
                préjudicierait les intérêts de RESA, de ses fournisseurs et/ou de ses partenaires.`}
            </p>
            <p>
                {`L’utilisateur renonce à ses droits de propriété intellectuelle sur l’ensemble des éléments qu’il dépose
                sur le portail.`}
            </p>
            <p>
                {`En aucun cas la responsabilité de RESA ne pourra être engagée par rapport au contenu du présent portail.`}
            </p>
            <p>
                {`RESA décline toute responsabilité pour tout contenu inadapté, illégitime ou illégal présent sur ce
                portail qui aurait été déposé par un utilisateur ainsi que pour les dommages pouvant résulter de leur
                consultation.`}
            </p>
            <p>
                {`RESA met tout en œuvre pour assurer la pérennité du service fourni à travers ce portail, toutefois sa
                responsabilité ne pourra être engagée en cas d’indisponibilité du présent portail.`}
            </p>
        </Modal>
    );
}

export default ConditionsGenerales;
