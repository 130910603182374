/* eslint-disable react/display-name */
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Space, Spin, Input, Typography, Row, Col, Empty, Menu } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import * as _ from 'lodash';

import { getChantiers } from '../../slices/chantiersSlice';
import { getReportages, resetReportageUpload } from '../../slices/reportagesSlice';
import { RootState } from '../../rootReducer';
import { Chantier, Reportage, Searched } from '../../helpers/types';
import ChantiersReportageForm from '../ChantiersReportageForm/ChantiersReportageForm';
import ReportageList from './ReportageList';
import Error from '../Errors/Error';
import RenderHighlighted from '../RenderHighlighted/RenderHighLighted';
import isSearched from '../../helpers/isSearched';

const { Search } = Input;

type EntrepreneurKeys = keyof Chantier;
const SEARCH_KEYS: EntrepreneurKeys[] = ['numero', 'street', 'postCode', 'localite', 'id'];
function isChantierSearched(e: Chantier, searched: Searched) {
    const fullSearchString: string = SEARCH_KEYS.map((k) => e[k] || '').join(' ');
    return isSearched(fullSearchString, searched);
}

type IMappingChantierIdToReportageList = {
    [key: string]: Reportage[];
};
interface IPopupChantierInfo {
    chantierId: string | null;
    powalcoId: string | null;
}
function ChantiersPage(): ReactElement {
    // fetching data:
    const dispatch = useDispatch();
    const chantiersRequest = useSelector((state: RootState) => state.chantiersSlice);
    const { request: reportagesRequest } = useSelector((state: RootState) => state.reportageSlice);
    useEffect(() => {
        dispatch(getChantiers(0, 10000));
    }, [dispatch]);
    useEffect(() => {
        if (!reportagesRequest.data || !reportagesRequest.data.length) {
            dispatch(getReportages());
        }
    }, [dispatch]);
    const getReportagesByChantier = (chantierId: string) =>
        (reportagesRequest.data || []).filter((reportage: Reportage) => reportage.chantierId === chantierId);

    // interactions for popup (adding reportage):
    const [popupChantierInfo, setPopupChantierInfo] = useState<IPopupChantierInfo>({
        chantierId: null,
        powalcoId: null,
    });
    const [popupReportageList, setPopupReportageList] = useState<Reportage[]>([]);
    function closeModal() {
        dispatch(resetReportageUpload());
        return setPopupChantierInfo({ powalcoId: null, chantierId: null });
    }
    useEffect(() => {
        const newPopupReportageList =
            popupChantierInfo && popupChantierInfo.chantierId
                ? getReportagesByChantier(popupChantierInfo.chantierId)
                : [];
        setPopupReportageList(newPopupReportageList);
    }, [popupChantierInfo.chantierId]);

    // interactions for chantier selection:
    const [selectedChantier, setSelectedChantier] = useState<Chantier>();

    // searching:
    const [searchedReportage, setSearchedReportage] = useState<Searched>(null);
    const [searchedChantier, setSearchedChantier] = useState<Searched>(null);
    const [filteredChantiers, setFilteredChantiers] = useState<Chantier[]>([]);
    const [
        filteredReportagesByChantierId,
        setFilteredReportagesByChantierId,
    ] = useState<IMappingChantierIdToReportageList>({});
    const getNumReportagesByChantier = (chantierId: string) =>
        (filteredReportagesByChantierId[chantierId] || []).length;
    useEffect(() => {
        // when searching a chantier, we filter the list of chantier
        const newFilteredChantiers = (chantiersRequest.data || [])
            .slice()
            .filter((chantier: Chantier) => isChantierSearched(chantier, searchedChantier))
            .sort((a, b) => getNumReportagesByChantier(b.id) - getNumReportagesByChantier(a.id));
        setFilteredChantiers(newFilteredChantiers);
    }, [chantiersRequest.data, searchedChantier, filteredReportagesByChantierId]);
    useEffect(() => {
        // when searching a reportage, we filter the list of reportages by chantiers
        if (chantiersRequest.data && reportagesRequest.data) {
            const filteredReportages = reportagesRequest.data.filter((reportage: Reportage) =>
                isSearched(reportage.fileName, searchedReportage),
            );
            const newFilteredReportagesByChantierId = _.groupBy(filteredReportages, 'chantierId');
            setFilteredReportagesByChantierId(newFilteredReportagesByChantierId);
        }
    }, [chantiersRequest.data, reportagesRequest.data, searchedReportage]);

    return (
        <div style={{ padding: 30 }}>
            <ChantiersReportageForm
                onClose={closeModal}
                chantierId={popupChantierInfo.chantierId}
                powalcoId={popupChantierInfo.powalcoId}
                otherReportages={popupReportageList}
            />
            <Space wrap size={32} align="center" style={{ marginBottom: 24 }}>
                <h2 style={{ fontSize: 48, marginBottom: 0 }}>Mes Chantiers</h2>
                <div>
                    <Search
                        placeholder="Rechercher un fichier"
                        value={searchedReportage || ''}
                        onChange={(e) => setSearchedReportage(e.target.value)}
                        allowClear
                        size="large"
                        style={{ width: 400 }}
                    />
                </div>
            </Space>
            <br />
            <Error request={chantiersRequest}>
                <div style={{ display: 'flex', maxWidth: '100%', height: 'calc(100vh - 220px)', overflowY: 'hidden' }}>
                    <div
                        style={{
                            maxWidth: 400,
                            width: '50%',
                            flexShrink: 0,
                            height: '100%',
                        }}
                    >
                        <Search
                            placeholder="Rechercher un chantier"
                            value={searchedChantier || ''}
                            onChange={(e) => setSearchedChantier(e.target.value)}
                            allowClear
                        />
                        {filteredChantiers.length === 0 && (
                            <Empty
                                style={{ borderRight: '1px solid #f0f0f0', margin: 0, padding: '32px 16px' }}
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="Aucun chantier ne correspond à votre recherche"
                            />
                        )}
                        <Menu
                            style={{
                                height: 'calc(100% - 30px)',
                                overflowY: 'scroll',
                            }}
                            onClick={(item) => {
                                setSelectedChantier(
                                    chantiersRequest.data?.find((chantier) => chantier.id === item.key),
                                );
                            }}
                        >
                            {filteredChantiers.map((chantier) => {
                                const nReportages = getNumReportagesByChantier(chantier.id);
                                return (
                                    <Menu.Item
                                        key={chantier.id}
                                        style={{ height: 'auto', lineHeight: 'normal', padding: 10 }}
                                    >
                                        <Row justify="space-between" style={{ width: '100%' }}>
                                            <Col>
                                                <div>
                                                    <RenderHighlighted
                                                        str={chantier.numero + ' ' + chantier.street}
                                                        searched={searchedChantier}
                                                    />
                                                </div>
                                                <div>
                                                    <RenderHighlighted
                                                        str={chantier.postCode + ' ' + chantier.localite}
                                                        searched={searchedChantier}
                                                    />
                                                </div>
                                                <small>
                                                    {'ID: '}
                                                    <RenderHighlighted str={chantier.id} searched={searchedChantier} />
                                                </small>
                                            </Col>
                                            <Col>
                                                {nReportages ? (
                                                    <span>
                                                        <Typography.Text type="secondary">
                                                            {nReportages} fichiers
                                                        </Typography.Text>
                                                    </span>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Menu.Item>
                                );
                            })}
                        </Menu>
                    </div>
                    <div style={{ flexShrink: 1, flexGrow: 1, height: '100%', overflowY: 'scroll' }}>
                        {selectedChantier ? (
                            <ReportageList
                                chantier={selectedChantier}
                                searched={searchedReportage}
                                reportages={filteredReportagesByChantierId[selectedChantier.id] || []}
                                setPopupChantierInfo={setPopupChantierInfo}
                            />
                        ) : (
                            <Spin spinning={reportagesRequest.loading}>
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        textAlign: 'center',
                                        fontSize: 24,
                                        color: 'lightgray',
                                    }}
                                >
                                    <div>
                                        <InfoCircleOutlined size={64} style={{ fontSize: 64, marginBottom: 12 }} />
                                    </div>
                                    <p>Aucun chantier selectionné</p>
                                </div>
                            </Spin>
                        )}
                    </div>
                </div>
            </Error>
        </div>
    );
}

export default ChantiersPage;
