import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import fr_FR from 'antd/es/locale/fr_FR';
import * as moment from 'moment';
import 'moment/locale/fr';

import store from './store';

import './index.css';

moment.locale('fr');
/*eslint-disable @typescript-eslint/no-var-requires */
const render = () => {
    const App = require('./App').default;

    ReactDOM.render(
        <Provider store={store}>
            <ConfigProvider locale={fr_FR}>
                <App />
            </ConfigProvider>
        </Provider>,
        document.getElementById('root'),
    );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', render);
}
