import moment from 'moment';
import { Entrepreneur } from '../helpers/types';
import { API } from './api';

async function create(e: Entrepreneur): Promise<Entrepreneur> {
    return API().post('/powalco/entrepreneurs/account', {
        Email: e.mail,
        // Phone: 'string',
    });
}
async function deactivate(e: Entrepreneur): Promise<Entrepreneur> {
    return API().patch('/powalco/entrepreneurs/account/enable', {
        Email: e.mail,
        Enabled: false,
    });
}
async function activate(e: Entrepreneur): Promise<Entrepreneur> {
    return API().patch('/powalco/entrepreneurs/account/enable', {
        Email: e.mail,
        Enabled: true,
    });
}

// only one possible action depending on the status
async function postEntrepreneur(e: Entrepreneur): Promise<Entrepreneur> {
    switch (e.status) {
        case 'sans compte':
            return create(e).then(() => ({ ...e, status: 'en attente', creationDate: +moment() }));
        case 'inactif':
            return activate(e).then(() => ({ ...e, status: 'en attente', deactivationDate: null }));
        // should status after this action be actif or en attente ? ...
        case 'actif':
        case 'en attente':
            return deactivate(e).then(() => ({ ...e, status: 'inactif', deactivationDate: +moment() }));
        default:
            throw new Error('Entrepreneur without status');
    }
}

export default postEntrepreneur;
