/* Entrepreneur */
export type Status = 'actif' | 'inactif' | 'en attente' | 'sans compte';
export interface Entrepreneur {
    id: string;
    name: string;
    mail: string;
    status: Status;
    creationDate: number | null;
    lastActivityDate: number | null;
    deactivationDate: number | null;
    fournisseurId: string;
}

/* Chantier */
export interface Chantier {
    id: string;
    street: string;
    numero: string;
    postCode: string;
    localite: string;
    powalcoId: string;
}

/* Reportage */

export const allReportageTypes = ['EDLE', 'EDLS'] as const;
export type ReportageType = typeof allReportageTypes[number];

export interface UploadStatus {
    success: boolean;
    timestamp: number;
    error?: string;
}
export interface Reportage {
    id?: string;
    type: ReportageType;
    date?: number;
    fileName: string;
    chantierId: string;
    url?: string;
    powalco_filename?: string;
    sharepointUpload?: UploadStatus;
    powalcoUpload?: UploadStatus;
}

/* Autres */
export interface Request<Type> {
    data?: Type;
    loading: boolean;
    pagination?: PaginationState;
    error?: string | null;
}

export interface PaginationState {
    PageSize: number;
    CurrentPage: number;
    NbPage: number;
}

export type Searched = string | null;
