import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import { getLoginToken, logout as logoutCall, getDashboard as getDashboardCall } from '../services/login';
import { Request } from '../helpers/types';

interface AuthSliceState {
    auth: {
        token: string | null;
        error?: string;
    };
    dashboard: Request<any>;
}

const initialState: AuthSliceState = {
    auth: {
        token: null,
    },
    dashboard: {
        loading: false,
    },
};

const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        //login
        dashboardRequest(state: AuthSliceState) {
            state.dashboard.loading = true;
            state.dashboard.data = null;
            state.dashboard.error = null;
        },
        dashboardSuccess(state: AuthSliceState, action: PayloadAction<any>) {
            state.dashboard.data = action.payload;
            state.dashboard.error = null;
            state.dashboard.loading = false;
        },
        dashboardFailure(state: AuthSliceState, action: PayloadAction<string>) {
            state.dashboard.loading = false;
            state.dashboard.error = action.payload;
        },
        logoutRequest(state: AuthSliceState) {
            state.auth.token = null;
            state.dashboard = { loading: false };
        },
        setToken(state: AuthSliceState, action: PayloadAction<string>) {
            state.auth.token = action.payload;
            state.auth.error = undefined;
        },
        setTokenError(state: AuthSliceState, action: PayloadAction<string>) {
            state.auth.error = action.payload;
        },
    },
});

export const {
    dashboardRequest,
    dashboardSuccess,
    dashboardFailure,
    logoutRequest,
    setToken,
    setTokenError,
} = authSlice.actions;

export const getDashboard = (): AppThunk => async (dispatch) => {
    dispatch(dashboardRequest());
    try {
        const dashboard = await getDashboardCall();
        dispatch(dashboardSuccess(dashboard));
    } catch (err) {
        dispatch(dashboardFailure(err.toString()));
    }
};

export const retrieveToken = (): AppThunk => async (dispatch) => {
    if (window.location.hash.includes('id_token')) {
        const token_ = window.location.hash.split('id_token=')[1];
        dispatch(setToken(token_));
        //window.location.hash = '';
        //window.location.search = '';
    }
    if (window.location.hash.includes('error_description')) {
        const err = window.location.hash.split('error_description=')[1];
        dispatch(setTokenError(err));
        //window.location.hash = '';
        //window.location.search = '';
    }
};

export const logout = (): AppThunk => async () => {
    await logoutCall();
};

export default authSlice.reducer;
