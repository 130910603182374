import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Reportage, Request } from '../helpers/types';
import { AppThunk } from '../store';
import { postReportage, getReportages as callGetReportages } from '../services/reportages';

interface UploadRequest<T> extends Request<T> {
    percentage?: number;
}
interface ReportageSliceState {
    upload: UploadRequest<Reportage>;
    request: Request<Reportage[]>;
}

const initialState: ReportageSliceState = {
    upload: {
        loading: false,
    },
    request: {
        loading: false,
    },
};

const reportagesSlice = createSlice({
    name: 'reportagesSlice',
    initialState,
    reducers: {
        getReportagesRequest(state: ReportageSliceState) {
            state.request.loading = true;
        },
        getReportagesSuccess(state: ReportageSliceState, action: PayloadAction<Reportage[]>) {
            state.request.loading = false;
            state.request.data = action.payload;
            state.request.error = null;
        },
        getReportagesFailure(state: ReportageSliceState, action: PayloadAction<string>) {
            state.request.loading = false;
            state.request.error = action.payload;
        },
        addReportageRequest(state: ReportageSliceState) {
            state.upload.loading = true;
            state.upload.percentage = 0;
        },
        addReportageLoading(state: ReportageSliceState, action: PayloadAction<number>) {
            state.upload.percentage = action.payload;
        },
        addReportageSuccess(state: ReportageSliceState, action: PayloadAction<Reportage>) {
            state.upload.loading = false;
            state.upload.data = action.payload;
            state.upload.error = null;
            state.upload.percentage = undefined;
        },
        addReportageFailure(state: ReportageSliceState, action: PayloadAction<string>) {
            state.upload.loading = false;
            state.upload.error = action.payload;
            state.upload.percentage = undefined;
        },
        resetReportageUpload(state: ReportageSliceState) {
            state.upload = initialState.upload;
        },
    },
});

export default reportagesSlice.reducer;

export const {
    addReportageRequest,
    addReportageLoading,
    addReportageSuccess,
    addReportageFailure,
    getReportagesRequest,
    getReportagesSuccess,
    getReportagesFailure,
    resetReportageUpload,
} = reportagesSlice.actions;

export const addReportage = (
    reportage: Reportage,
    chantierId: string,
    powalcoId: string,
    file: File,
): AppThunk => async (dispatch) => {
    dispatch(addReportageRequest());
    try {
        const addedReportage = await postReportage(reportage, chantierId, powalcoId, file);
        dispatch(addReportageSuccess(addedReportage));
        dispatch(getReportages());
        setTimeout(() => dispatch(getReportages()), 15000);
    } catch (err) {
        dispatch(addReportageFailure(err.toString()));
        throw err;
    }
};

export const getReportages = (): AppThunk => async (dispatch) => {
    dispatch(getReportagesRequest());
    try {
        const getReportages_ = await callGetReportages();
        dispatch(getReportagesSuccess(getReportages_));
    } catch (err) {
        dispatch(getReportagesFailure(err.toString()));
    }
};
