import { PaginationState } from './../helpers/types';
import { Chantier } from '../helpers/types';
import { API } from './api';

import mapping from '../mappings/chantier';

async function fetchChantiers(Page = 0, PageSize = 20): Promise<[Chantier[], PaginationState]> {
    const test = await API().get('/powalco/chantiers', { params: { PageSize, Page } });
    const {
        data: { Data: serverChantiers, Pagination },
    } = test;
    const chantiers = mapping(serverChantiers);
    return [chantiers, Pagination];
}

export default fetchChantiers;
