import React, { ReactElement, useState } from 'react';
import { Modal, Form, Select, Upload, Spin, Typography, Progress, Alert, Row, Col, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { InboxOutlined, LeftOutlined } from '@ant-design/icons';

import { RootState } from '../../rootReducer';
import { addReportage } from '../../slices/reportagesSlice';
import { allReportageTypes, ReportageType, Reportage } from '../../helpers/types';
import PDFViewer from '../PDFViewer/PDFViewer';
import ReportageDetails from '../ChantiersPage/ReportageDetails';

interface IChantierReportageForm {
    chantierId: string | null;
    powalcoId: string | null;
    onClose: () => void;
    otherReportages?: Reportage[];
}

interface TempSubmission {
    reportage: Reportage;
    file: File;
}
/* eslint-disable @typescript-eslint/no-explicit-any */
const normFile = (e: any) => {
    return e && e.fileList && Array.isArray(e.fileList) && e.fileList.filter((f: any) => f.uid === e.file.uid);
};
/* eslint-enable @typescript-eslint/no-explicit-any */

function validateFile(files: File[]) {
    if (!files || !Array.isArray(files) || files.length === 0) {
        return Promise.reject('Veuillez sélectionner un document');
    }
    const file = files[0];
    const { name, size } = file;
    if (!name.endsWith('.pdf')) {
        return Promise.reject('Seuls les fichiers pdf sont acceptés.');
    }
    if (size > 100 * 1024 * 1024) {
        return Promise.reject('La taille du fichier ne peut dépasser 100 MB');
    }
    return Promise.resolve();
}

function ChantiersReportageForm({ chantierId, onClose, powalcoId }: IChantierReportageForm): ReactElement | null {
    const dispatch = useDispatch();
    const { upload: uploadRequest } = useSelector((state: RootState) => state.reportageSlice);
    const [form] = Form.useForm();
    const [tempSubmission, setTempSubmission] = useState<TempSubmission | null>(null);

    async function onValidateForm() {
        if (uploadRequest.loading) return null;
        const values = await form.validateFields();
        const { reportageType, file } = values;
        const { name: fileName } = file[0];
        const reportage: Reportage = {
            type: reportageType as ReportageType,
            fileName,
            chantierId: chantierId || 'null',
        };
        setTempSubmission({ reportage, file: file[0] });
    }
    async function onConfirm() {
        if (uploadRequest.loading) return null;
        if (!tempSubmission) return null;
        await dispatch(
            addReportage(tempSubmission.reportage, chantierId || 'null', powalcoId || 'null', tempSubmission.file),
        );
        form.resetFields();
        setTempSubmission(null);
        return onClose();
    }
    if (!chantierId) {
        return null;
    }
    const title = `Ajouter un reportage au chantier ${chantierId}`;
    if (uploadRequest.loading) {
        return (
            <Modal visible title={title} closable={false} footer={false}>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <div>
                        <Spin />
                    </div>
                    <Typography.Text strong style={{ fontSize: 18 }}>
                        Envoi du document en cours...
                    </Typography.Text>
                    <Progress percent={Math.floor((uploadRequest.percentage || 0) * 100)} strokeColor="#fa8c16" />
                </div>
            </Modal>
        );
    }
    if (tempSubmission) {
        const reportageWithLocalUrl = {
            ...tempSubmission.reportage,
            url: URL.createObjectURL((tempSubmission.file as any).originFileObj),
            powalco_filename: '-',
        };
        return (
            <Modal
                visible
                title={title}
                maskClosable={false}
                onCancel={() => {
                    setTempSubmission(null);
                    form.resetFields();
                    onClose();
                }}
                footer={[
                    <Button key="back" onClick={() => setTempSubmission(null)} icon={<LeftOutlined />}>
                        Retour
                    </Button>,
                    <Button key="send" onClick={onConfirm} type="primary">
                        Confirmer
                    </Button>,
                ]}
            >
                <ReportageDetails confirmation reportage={reportageWithLocalUrl} />
            </Modal>
        );
    }
    return (
        <Modal
            visible
            title={title}
            maskClosable={false}
            okText="Envoyer"
            cancelText="Annuler"
            onCancel={() => {
                form.resetFields();
                onClose();
            }}
            onOk={onValidateForm}
        >
            <Form form={form} layout="vertical" name="form_in_modal" initialValues={{}}>
                {uploadRequest.error && <Alert type="error" message={uploadRequest.error} />}
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="Type de reportage"
                            required
                            name="reportageType"
                            className="collection-create-form_last-form-item"
                            rules={[{ required: true, message: 'Veuillez sélectionner un type de reportage' }]}
                        >
                            <Select placeholder="Choisir un type de reportage">
                                {allReportageTypes.map((type) => (
                                    <Select.Option key={type} value={type}>
                                        {type}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name="file"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[{ validator: (r, f) => validateFile(f) }]}
                >
                    <Upload.Dragger
                        name="files"
                        beforeUpload={() => false}
                        accept="application/pdf"
                        itemRender={(originNode, file) => (
                            <div>
                                {originNode}
                                <Row gutter={8} justify="center">
                                    <Col span={12}>
                                        <PDFViewer url={URL.createObjectURL(file.originFileObj)} />
                                    </Col>
                                </Row>
                            </div>
                        )}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Déposez votre document ici</p>
                    </Upload.Dragger>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ChantiersReportageForm;
