import { Chantier, Request, PaginationState } from './../helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import fetchChantiers from '../services/fetchChantier';

type ChantierSliceState = Request<Chantier[]>;

const initialState: ChantierSliceState = {
    data: [],
    loading: false,
    error: null,
};

const chantierSlice = createSlice({
    name: 'chantierSlice',
    initialState,
    reducers: {
        getChantiersRequest(state) {
            state.loading = true;
        },
        getChantiersSuccess(state, action: PayloadAction<[Chantier[], PaginationState]>) {
            const [data, pagination] = action.payload;
            state.data = data;
            state.pagination = pagination;
            state.loading = false;
        },
        getChantiersFailure(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export const { getChantiersRequest, getChantiersSuccess, getChantiersFailure } = chantierSlice.actions;

export default chantierSlice.reducer;

export const getChantiers = (page: number, pageSize: number): AppThunk => async (dispatch) => {
    dispatch(getChantiersRequest());
    try {
        const [data, pagination] = await fetchChantiers(page, pageSize);
        dispatch(getChantiersSuccess([data, pagination]));
    } catch (err) {
        dispatch(getChantiersFailure(err.toString()));
    }
};
