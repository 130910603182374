import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Tooltip, Tag, Button, Popconfirm, List } from 'antd';
import { ArrowRightOutlined, HourglassOutlined, WarningOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

import { editEntrepreneur } from '../../slices/entrepreneursSlice';
import { Entrepreneur, Searched } from '../../helpers/types';
import RenderHighlighted from '../RenderHighlighted/RenderHighLighted';

function renderDate(mmt: number): string | null {
    if (mmt) {
        return moment(mmt).calendar();
    }
    return null;
}

const MAX_STRING_LENGTH = 24;

function ellipsis(text: string | null): string {
    if (!text) {
        return '';
    }
    if (text.length > MAX_STRING_LENGTH) {
        return `${text.substring(0, MAX_STRING_LENGTH)}...`;
    }
    return text;
}

function renderActionBtns(e: Entrepreneur, dispatch: any): ReactElement | null {
    switch (e.status) {
        case 'inactif':
            return (
                <Button
                    style={{ position: 'absolute', bottom: 32 }}
                    type="primary"
                    shape="round"
                    onClick={() => dispatch(editEntrepreneur(e))}
                >
                    <PlusOutlined /> Réactiver
                </Button>
            );
        case 'sans compte':
            return (
                <Button
                    style={{ position: 'absolute', bottom: 32 }}
                    type="primary"
                    shape="round"
                    onClick={() => dispatch(editEntrepreneur(e))}
                >
                    <PlusOutlined /> Activer
                </Button>
            );
        case 'actif':
        case 'en attente':
            return (
                <Popconfirm
                    title={`Êtes-vous sûr de désactiver ${e.name}?`}
                    onConfirm={() => dispatch(editEntrepreneur(e))}
                    okText="oui"
                    cancelText="non"
                >
                    <Button style={{ position: 'absolute', bottom: 32 }} danger shape="round">
                        <WarningOutlined /> Désactiver
                    </Button>
                </Popconfirm>
            );
        default:
            return null;
    }
}

function renderInfos(e: Entrepreneur, searched: Searched): ReactElement {
    const highlight = (str: string) => (
        <RenderHighlighted str={str} searched={searched} /*customRenderer={ellipsis}*/ />
    );
    return (
        <div style={{ maxWidth: '100%' }}>
            <strong style={{ fontSize: 20 }}>{highlight(e.name)}</strong>
            <div>
                <small>ID: {highlight(e.fournisseurId)}</small>
            </div>
            <div style={{ overflowX: 'hidden' }}>
                <a style={{ color: '#0366d6', maxWidth: '100%' }} href={`mailto:${e.mail}`}>
                    {highlight(e.mail)}
                </a>
            </div>
        </div>
    );
}

function isActif(e: Entrepreneur): boolean {
    return e.status === 'actif' || e.status === 'en attente';
}

function renderActivityInfo(e: Entrepreneur): ReactElement {
    return (
        <div style={{ marginTop: 8, marginBottom: 8, overflowX: 'hidden' }}>
            {!!e.lastActivityDate ? (
                <Button type="link" style={{ padding: 0 }} size="small">
                    Voir les états des lieux <ArrowRightOutlined />
                </Button>
            ) : e.status === 'en attente' ? (
                <Tooltip title="Le compte sera actif quand l'entrepreneur se sera connecté pour la 1e fois">
                    <Tag color="blue" icon={<HourglassOutlined />}>
                        En attente du 1e login
                    </Tag>
                </Tooltip>
            ) : (
                <Button type="link" style={{ padding: 0 }} disabled size="small">
                    {"pas encore d'état des lieux"}
                </Button>
            )}
        </div>
    );
}

type EntrepreneurListProps = {
    loading: boolean;
    data: Entrepreneur[] | null;
    searched: Searched;
};

function EntrepreneurList(props: EntrepreneurListProps): ReactElement {
    const dispatch = useDispatch();
    const { loading, data, searched } = props;

    return (
        <List
            grid={{
                xs: 1,
                sm: 2,
                md: 2,
                lg: 4,
                xl: 4,
                xxl: 4,
            }}
            loading={loading}
            dataSource={data || []}
            locale={{ emptyText: 'Aucun résultat' }}
            renderItem={(e) => (
                <Card bodyStyle={{ paddingBottom: 2, height: 220, minWidth: 160 }} style={{ margin: 8, minWidth: 160 }}>
                    {renderInfos(e, searched)}
                    {isActif(e) && renderActivityInfo(e)}
                    <div style={{ marginTop: 24, marginBottom: 12 }}>{renderActionBtns(e, dispatch)}</div>
                    {isActif(e) && e.creationDate && (
                        <div
                            style={{
                                color: 'silver',
                                borderTop: '1px solid whitesmoke',
                                position: 'absolute',
                                bottom: 4,
                            }}
                        >
                            <small>Créé {renderDate(e.creationDate)}</small>
                        </div>
                    )}
                </Card>
            )}
        />
    );
}

export default EntrepreneurList;
