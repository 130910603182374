import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import { Entrepreneur } from '../helpers/types';
import fetchEntrepreneurs from '../services/fetchEntrepreneurs';
import postEntrepreneur from '../services/postEntrepreneur';

interface EntrepreneursSliceState {
    data: Entrepreneur[];
    error: string | null;
    loading: boolean;
    editing: string | null;
    resettingPwd: string | null;
}

const initialState: EntrepreneursSliceState = {
    data: [],
    error: null,
    loading: false,
    editing: null,
    resettingPwd: null,
};

const entrepreneursSlice = createSlice({
    name: 'entrepreneursSlice',
    initialState,
    reducers: {
        //fetch
        getEntrepreneursRequest(state: EntrepreneursSliceState) {
            state.loading = true;
        },
        getEntrepreneursSuccess(state: EntrepreneursSliceState, action: PayloadAction<Entrepreneur[]>) {
            state.data = action.payload;
            state.error = null;
            state.loading = false;
        },
        getEntrepreneursFailure(state: EntrepreneursSliceState, action: PayloadAction<string>) {
            state.data = initialState.data;
            state.error = action.payload;
            state.loading = false;
        },
        // editing
        postEntrepreneurRequest(state: EntrepreneursSliceState, action: PayloadAction<Entrepreneur>) {
            state.editing = action.payload.id;
        },
        postEntrepreneurSuccess(state: EntrepreneursSliceState, action: PayloadAction<Entrepreneur>) {
            if (state.editing === action.payload.id) {
                state.data = state.data.map((e) => (e.id === action.payload.id ? action.payload : e));
                state.error = null;
                state.editing = null;
            }
        },
        postEntrepreneurFailure(state: EntrepreneursSliceState, action: PayloadAction<string>) {
            state.error = action.payload;
            state.editing = null;
        },
        // resettingPwd
        resetPasswordRequest(state: EntrepreneursSliceState, action: PayloadAction<Entrepreneur>) {
            state.resettingPwd = action.payload.id;
        },
        resetPasswordSuccess(state: EntrepreneursSliceState, action: PayloadAction<Entrepreneur>) {
            if (state.resettingPwd === action.payload.id) {
                state.error = null;
                state.resettingPwd = null;
            }
        },
        resetPasswordFailure(state: EntrepreneursSliceState, action: PayloadAction<string>) {
            state.error = action.payload;
            state.resettingPwd = null;
        },
    },
});

export const {
    getEntrepreneursSuccess,
    getEntrepreneursFailure,
    getEntrepreneursRequest,
    postEntrepreneurRequest,
    postEntrepreneurSuccess,
    postEntrepreneurFailure,
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,
} = entrepreneursSlice.actions;

export const getEntrepreneurs = (): AppThunk => async (dispatch) => {
    dispatch(getEntrepreneursRequest());
    try {
        const data = await fetchEntrepreneurs();
        dispatch(getEntrepreneursSuccess(data));
    } catch (err) {
        dispatch(getEntrepreneursFailure(err.toString()));
    }
};

export const editEntrepreneur = (oldEntrepreneur: Entrepreneur): AppThunk => async (dispatch) => {
    dispatch(postEntrepreneurRequest(oldEntrepreneur));
    try {
        const newEntrepreneur = await postEntrepreneur(oldEntrepreneur);
        dispatch(postEntrepreneurSuccess(newEntrepreneur));
    } catch (err) {
        dispatch(postEntrepreneurFailure(err.toString()));
    }
};

export const resetPassword = (e: Entrepreneur): AppThunk => async (dispatch) => {
    dispatch(resetPasswordRequest(e));
    try {
        await resetPassword(e);
        dispatch(resetPasswordSuccess(e));
    } catch (err) {
        dispatch(resetPasswordFailure(err.toString()));
    }
};

export default entrepreneursSlice.reducer;
