import { Entrepreneur, Status } from '../helpers/types';

interface IServerEntrepreneur {
    Id: string;
    Name: string;
    Email: string;
    Status: Status;
    CreationDate: number | null;
    LastActivityDate: number | null;
    DeactivationDate: number | null;
    FournisseurId: string;
}

function mapServerEntrepreneurToEntrepreneur(serverEntrepreneur: IServerEntrepreneur): Entrepreneur {
    return {
        id: serverEntrepreneur.Id,
        name: serverEntrepreneur.Name,
        mail: serverEntrepreneur.Email,
        status: serverEntrepreneur.Status,
        creationDate: serverEntrepreneur.CreationDate,
        lastActivityDate: serverEntrepreneur.LastActivityDate,
        deactivationDate: serverEntrepreneur.DeactivationDate,
        fournisseurId: serverEntrepreneur.FournisseurId,
    };
}

function mapServerEntrepreneursToEntrepreneurs(serversEntrepreneurs: IServerEntrepreneur[]): Entrepreneur[] {
    return serversEntrepreneurs.map(mapServerEntrepreneurToEntrepreneur);
}

export default mapServerEntrepreneursToEntrepreneurs;
