import * as _ from 'lodash';
import { Searched } from './types';

function sanitizeStr(str: string) {
    return _.deburr(str.toLowerCase()); // deburr removes accents
}

export default function isSearched(str: string, searched: Searched) {
    if (!searched) {
        return true;
    }
    return sanitizeStr(str).includes(sanitizeStr(searched));
}
