import { UploadStatus } from './../helpers/types';
import { Reportage, ReportageType } from '../helpers/types';

interface IServerReportage {
    FileName: string;
    EntrepreneurId: string;
    UploadedAt: number;
    Chantier: string;
    Type: ReportageType;
    Resource: string;
    URL: string;
    RenamedFile: string;
    SharepointUpload?: any;
    PowalcoUpload?: any;
}

function mapUploadStatus(status: any): UploadStatus {
    return (
        status && {
            success: status.Success,
            timestamp: status.Timestamp,
            error: status.Error,
        }
    );
}

function mapServerReportageToReportage(serverReportage: IServerReportage): Reportage {
    return {
        id: serverReportage.Resource,
        type: serverReportage.Type,
        date: serverReportage.UploadedAt,
        fileName: serverReportage.FileName,
        chantierId: serverReportage.Chantier,
        url: serverReportage.URL,
        powalco_filename: serverReportage.RenamedFile,
        sharepointUpload: mapUploadStatus(serverReportage.SharepointUpload),
        powalcoUpload: mapUploadStatus(serverReportage.PowalcoUpload),
    };
}

export default mapServerReportageToReportage;
