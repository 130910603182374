import { notification } from 'antd';
import axios from 'axios';
import { addReportageLoading } from '../slices/reportagesSlice';
import store from '../store';

export async function uploadFile(upload: any, file: any): Promise<null> {
    const { url, fields } = upload;
    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value as string);
    });

    formData.append('file', file.originFileObj);

    return axios
        .post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) =>
                store.dispatch(addReportageLoading(progressEvent.loaded / progressEvent.total)),
        })
        .catch((err) => {
            notification.error({
                message: "Erreur lors de l'upload du fichier",
                duration: 4,
                style: { backgroundColor: '#fff2f0', border: '1px solid #ffccc7' },
                description: err,
            });
        })
        .then(() => null); //TODO: fix it
}
