import { Pagination, Result, Spin } from 'antd';
import React, { FC, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

interface PDFViewerProps {
    url: string;
}

type PDFDocumentProxy = any; // TODO: import it correctly

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer: FC<PDFViewerProps> = ({ url }: PDFViewerProps) => {
    const [pdfState, setPdfState] = useState<PDFDocumentProxy>();
    const [page, setPage] = useState(1);
    const ref = useRef<HTMLDivElement>(null);
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                maxWidth: Math.min(ref?.current?.offsetWidth || 400, 400),
                margin: '0 auto',
            }}
            ref={ref}
        >
            <div style={pdfState ? { border: '1px solid rgba(0,0,0,0.1)' } : {}}>
                <Document
                    file={url}
                    onLoadSuccess={setPdfState}
                    loading={() => <Spin />}
                    error={() => <Result status="error" subTitle="Erreur lors du chargement du fichier" />}
                >
                    <Page
                        width={Math.min(ref?.current?.offsetWidth || 400, 400)}
                        renderAnnotationLayer={false}
                        pageNumber={page}
                    />
                </Document>
            </div>
            {pdfState && (
                <Pagination
                    style={{ marginTop: 16 }}
                    simple
                    current={page}
                    pageSize={1}
                    total={pdfState.numPages}
                    onChange={setPage}
                />
            )}
        </div>
    );
};

export default PDFViewer;
