const colors = {
    orange: '#FF4C02',
    lightorange: '#f9bc00',
    yellow: '#f8e71b',
    blue: '#1c5095',
    grey: '#2d2d2d',
    black: '#1b1b1b',
};

export default colors;
